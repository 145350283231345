import React from 'react'
import styled from 'styled-components'

import { useSpring, animated } from 'react-spring'

import linkImage from '../../images/link.png'
import Paragraph from '../Shared/Paragraph'
import Content from '../Shared/Content'

const ProjectTitle = styled(animated.a)`
  display: flex;
  align-items: center;
  color: #f4f3f1;
  font-weight: bold;
  font-size: 2rem;
  font-family: 'Rubik', sans-serif;
  margin-top: 0px;

  @media only screen and (max-width: 600px) {
    font-size: 1.75rem;
  }
`

const LinkIcon = styled.img`
  margin-left: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;

  @media only screen and (max-width: 600px) {
    height: 1.25rem;
    width: 1.25rem;
  }
`
const Tech = styled.img`
  height: 2.5rem;
  margin-right: 0.5rem;
  width: 2.5rem;
`
const Demo = styled.a`
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #f4f3f1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.75rem;
  }
`

export const Project = (props) => {
  return (
    <React.Fragment>
      <Content>
        <ProjectTitle href={props.repo}>
          {props.title}
          <LinkIcon src={linkImage} />
        </ProjectTitle>
        <div>
          {props.tech.map((imgSrc, index) => (
            <Tech src={imgSrc} key={index} />
          ))}
        </div>
        <Paragraph>{props.description}</Paragraph>
        <Demo href={props.demo}>
          Demo
          <LinkIcon src={linkImage} />
        </Demo>
      </Content>
    </React.Fragment>
  )
}
