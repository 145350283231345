import { Link } from 'react-router-dom'
import { useSpring } from 'react-spring'

import Back from '../Shared/Back'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import Paragraph from '../Shared/Paragraph'
import Content from '../Shared/Content'

import backArrow from '../../images/back-arrow.svg'

export const About = (props) => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } })

  return (
    <Container style={fade}>
      <Title>About</Title>
      <Content>
        <Paragraph>
          I'm a 26 year old self taught software developer. I began writing code
          in 2018, where I stumbled relentlessly through tutorials trying to get
          a grasp on functions, loops, and variables. A couple of months later I
          got an educational internship at Arcane Software. I worked on code in
          my free time for the next 3 years. At the beginning of 2021, I was
          offered a full-time position as a software developer.
        </Paragraph>
      </Content>

      <Link to="/">
        <Back src={backArrow} />
      </Link>
    </Container>
  )
}
