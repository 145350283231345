import styled, { keyframes } from 'styled-components'
import { animated } from 'react-spring'

const fade = keyframes`
  from {
      opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled(animated.div)`
  width: 40rem;

  @media only screen and (max-width: 600px) {
    text-align: center;
    width: 90%;
  }
`
export default Container
