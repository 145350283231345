import { useState } from 'react'
import { projects } from '../../resources/projects'
import { Link } from 'react-router-dom'
import { useSpring, animated, useTransition } from 'react-spring'

import Back from '../Shared/Back'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import { Project } from '../Portfolio/Project'

import backArrow from '../../images/back-arrow.svg'
import nextArrow from '../../images/next.svg'
import styled from 'styled-components'

const Arrows = styled.div`
  cursor: pointer;
`
const Down = styled.div`
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-style: solid;
  border-color: #f4f3f1;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-;
`
const Right = styled.img`
  filter: invert(99%) sepia(46%) saturate(742%) hue-rotate(301deg)
    brightness(115%) contrast(91%);
  height: 2rem;
  width: 2rem;
`
const Left = styled.img`
  filter: invert(99%) sepia(46%) saturate(742%) hue-rotate(301deg)
    brightness(115%) contrast(91%);
  height: 2rem;
  width: 2rem;
  transform: rotate(180deg);
`
// const AnimatedProject = animated(Project)

const Projects = styled.div`
  display: flex;
  align-items: center;
`
const Navigation = styled.div`
  margin-left: 1.5rem;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`
const Index = styled.div`
  text-align: center;
  margin-bottom: 0.75rem;
`
const MobileNavigation = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
`
const MobileIndex = styled(Index)`
  @media only screen and (max-width: 600px) {
    margin-right: 1rem;
    margin-bottom: 0px;
    margin-left: 1rem;
    font-size: 2rem;
  }
`
const Up = styled.div`
  margin-bottom: 1rem;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-style: solid;
  border-color: #f4f3f1;
  border-width: 0px 3px 3px 0px;
  transform: rotate(-135deg);
  transition: border-width 150ms ease-;
`

export const Portfolio = (props) => {
  const [activeID, changeID] = useState(0)
  const [toggled, toggle] = useState(true)

  const fade = useSpring({ opacity: toggled ? 1 : 0 })

  const increment = () => {
    if (activeID < projects.length - 1) changeID(activeID + 1)
  }

  const decrement = () => {
    if (activeID > 0) changeID(activeID - 1)
  }

  const scroll = (e) => {
    console.log(e.deltaY)

    if (e.deltaY > 0) {
      increment()
    }
    if (e.deltaY < 0) {
      decrement()
    }
  }

  document.onwheel = scroll

  return (
    <Container>
      <Title>Portfolio</Title>

      <MobileNavigation>
        <Left src={nextArrow} onClick={decrement} />
        <MobileIndex>{activeID + 1}</MobileIndex>
        <Right src={nextArrow} onClick={increment} />
      </MobileNavigation>

      <Projects>
        <animated.div style={fade}>
          <Project
            title={projects[activeID].title}
            description={projects[activeID].description}
            tech={projects[activeID].tech}
            repo={projects[activeID].repo}
            demo={projects[activeID].demo}
            toggled={toggled}
            toggle={toggle}></Project>
        </animated.div>

        <Navigation>
          <Index>{activeID + 1}</Index>
          <Arrows>
            <Up onClick={increment}></Up>

            <Down onClick={decrement}></Down>
          </Arrows>
        </Navigation>
      </Projects>

      <Link to="/">
        <Back src={backArrow} alt="back arrow" />
      </Link>
    </Container>
  )
}
