import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Top = styled(animated.div)`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`
const Me = styled.div`
  text-align: right;
  margin-right: 2rem;
  color: #18ccf1;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
  }
`
const Name = styled.div`
  font-size: 7.5rem;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 4.5rem;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`

const Divider = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border-top: 3px solid #f4f3f1;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 0.75rem;

    @media only screen and (max-width: 600px) {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`

const Gitlab = styled.img`
  width: 3rem;
  height: 3rem;
`

export const Menu = (props) => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } })

  return (
    <Container>
      <Top style={fade}>
        <Me>
          <Name>
            <div>Justin</div>
            <div>Moore</div>
          </Name>
        </Me>

        <Links>
          <Link to="/about">
            <div>About</div>
          </Link>
          <Link to="/portfolio">
            <div>Portfolio</div>
          </Link>

          <a href="/resume.pdf" download>
            <div>Resume</div>
          </a>
        </Links>
      </Top>

      <animated.div style={fade}>
        <Divider />
        <Footer>
          <a href="mailto:jdmdevelops@gmail.com">jdmdevelops@gmail.com</a>
          <a href="https://gitlab.com/jdmdevelops/">
            <Gitlab src="https://about.gitlab.com/images/press/logo/png/gitlab-icon-rgb.png" />
          </a>
        </Footer>
      </animated.div>
    </Container>
  )
}
