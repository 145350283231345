import '../App.css'

import styled from 'styled-components'

import { Portfolio } from './Portfolio/Portfolio'
import { About } from './About/About'
import { Menu } from './Menu/Menu'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

// TODO: ADD animations
// TODO: Rewrite bio
// TODO: Mobile support

const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Cabin', sans-serif;
  background-color: #322c56;
  color: #f4f3f1;

  @media only screen and (max-width: 600px) {
    font-size: 1.4rem;
  }
  a {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
    display: block;
  }
`

export const App = () => {
  return (
    <Router>
      <Main>
        <Switch>
          <Route exact path="/">
            <Menu />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
        </Switch>
      </Main>
    </Router>
  )
}
