import reactLogo from '../images/react-logo.png'
import svelteLogo from '../images/Svelte_Logo.svg'
import rustLogo from '../images/rust-logo-white.png'
import mySQLLogo from '../images/my-sql-logo.png'

export const projects = [
  {
    title: 'Offsite-Repair',
    repo: 'https://github.com/charliethomson/ticket',
    description: `The goal of this project was to create a ticketing system that my co-worker and I could utilize at our current place of work to keep
      track of offsite motherboard repairs. This is the first project where I pair programmed with another developer writing the back-end.
      This application taught me how to design and build a front end to fully support data from a RESTful API.`,
    tech: [svelteLogo, rustLogo, mySQLLogo],
  },
  {
    title: 'At-Home-Workout',
    repo: 'https://gitlab.com/jdmdevelops/at-home-workout',
    description: `The idea of this project was to create a semi-random at home workout that could be used at home during quarentine. The challenges that I overcame in this project were working with global state as well as creating and optimizing animations in CSS and
      Javascript.
      `,
    tech: [svelteLogo],
    demo: 'https://at-home-workout.now.sh/',
  },
  {
    title: 'RS Efficient Ironman Guide',
    repo: 'https://gitlab.com/jdmdevelops/rs-efficient-ironman-guide',
    description: `This project was a simple way to help me with my progress in a video game as well as hone my skills in development. It was my first real project and it helped me understand some of the solutions that modern front end frameworks provide like components and state management.
      
      `,
    tech: [svelteLogo],
    demo: 'https://rs-efficient-ironman-guide.jdmdevelops.now.sh/',
  },
  {
    title: 'Portfolio',
    repo: 'https://gitlab.com/jdmdevelops/portfolio',
    description: `The vision for this project was to create a minimalist, functional portfolio page. I decided to use React for this project to refamiliarlize myself with the framework. I was able to experiment with the hooks api and stretch my legs in React after learning the fundamentals of an easier component based front-end framework Svelte. 
      `,
    tech: [reactLogo],
    demo: '/',
  },
]
